"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainMenuScene = void 0;
const constants_1 = require("../constants");
const AudioManager_1 = require("../utils/AudioManager");
const http_helper_1 = require("../utils/http-helper");
const logger_1 = require("../utils/logger");
const score_local_storage_1 = require("../utils/score-local-storage");
const congratulation_scene_1 = require("./congratulation-scene");
const game_over_1 = require("./game-over");
class MainMenuScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'MainMenuScene',
        });
    }
    preload() {
        this.game.audioManager = new AudioManager_1.default(this);
        this.game.audioManager.loadSounds();
    }
    create() {
        this._audioManager = this.game.audioManager;
        this._audioManager.initialAddSound();
        // const { width, height } = this.sys.game.canvas;
        // // const backgroundImage = this.add.image(0, 0, 'landingBackground');
        // // backgroundImage.setOrigin(0, 0);
        // // backgroundImage.displayWidth = width;
        // // backgroundImage.displayHeight = height;
        this.createUserForm();
        this.addBackgroundImage();
        this._audioManager.playBackgroundMusic();
        this.playGameImagButton.addEventListener('click', () => {
            const playerNameInput = document.getElementById('playerName');
            const emailInput = document.getElementById('userEmail');
            const playerName = playerNameInput.value;
            const email = emailInput.value;
            (0, http_helper_1.getUserId)(email, playerName)
                .then(data => {
                this.registry.set('userId', (data === null || data === void 0 ? void 0 : data.userId) || 0);
            })
                .catch(ex => { });
            const scoreLocalStorage = new score_local_storage_1.default();
            scoreLocalStorage.savePlayerNameAndEmail(playerName, email);
            (0, logger_1.logLevelStart)(1, playerName, email);
            this.scene.start('GameScene');
        });
        this.playGameImagButton.addEventListener('mouseover', () => {
            this.playGameImagButton.classList.add('gap-button-hover');
        });
        this.playGameImagButton.addEventListener('mouseout', () => {
            this.playGameImagButton.classList.remove('gap-button-hover');
        });
        if (!this.scene.manager.keys.hasOwnProperty('GameOver')) {
            this.scene.add('GameOver', game_over_1.GameOverScene, false);
        }
        if (!this.scene.manager.keys.hasOwnProperty('CongratulationScene')) {
            this.scene.add('CongratulationScene', congratulation_scene_1.CongratulationScene, false);
        }
        this.events.on('shutdown', () => {
            const playerNameInput = document.getElementById('playerName');
            const emailInput = document.getElementById('userEmail');
            const gap_dash_form = document.getElementById('gap_dash_form');
            const bg = document.getElementById('menu_sence_bg');
            const loadingWrapper = document.getElementById('loading-wrapper');
            if (bg) {
                bg.remove();
            }
            if (loadingWrapper)
                loadingWrapper.remove();
            if (playerNameInput)
                playerNameInput.remove();
            if (emailInput)
                emailInput.remove();
            if (gap_dash_form)
                gap_dash_form.remove();
        });
    }
    addBackgroundImage() {
        const divWrapper = document.createElement('div');
        divWrapper.id = 'menu_sence_bg';
        divWrapper.className = 'common-sence-bg';
        this.add.dom(0, 0).createFromHTML(divWrapper.outerHTML);
        document.body.appendChild(divWrapper);
    }
    createUserForm() {
        const localStorage = new score_local_storage_1.default();
        let gapDashForm = document.createElement('div');
        gapDashForm.id = 'gap_dash_form';
        gapDashForm.style.position = 'absolute';
        let title = document.createElement('h1');
        title.innerHTML = 'GAP DASH';
        title.className = 'title-gap-dash';
        const gameLogo = document.createElement('img');
        gameLogo.src = 'assets/images/icons/game-icon.webp';
        gameLogo.className = 'game-logo-gap-dash';
        let playerNameInput = document.createElement('input');
        playerNameInput.className = 'input-gap-dash';
        playerNameInput.type = 'text';
        playerNameInput.id = 'playerName';
        playerNameInput.placeholder = 'Enter your name';
        playerNameInput.value = localStorage.getPlayerName();
        let playerEmailInput = document.createElement('input');
        playerEmailInput.className = 'input-gap-dash';
        playerEmailInput.type = 'text';
        playerEmailInput.id = 'userEmail';
        playerEmailInput.placeholder = 'Enter your email';
        playerEmailInput.value = localStorage.getPlayerEmail();
        this.playGameImagButton = document.createElement('button');
        this.playGameImagButton.type = 'submit';
        this.playGameImagButton.id = 'submitButton';
        this.playGameImagButton.className = 'gap_button';
        this.playGameImagButton.innerHTML = 'PLAY GAME';
        const backButton = document.createElement('span');
        backButton.innerText = 'Return to the homepage';
        backButton.className = 'back-button';
        gapDashForm.appendChild(title);
        gapDashForm.appendChild(gameLogo);
        gapDashForm.appendChild(playerNameInput);
        gapDashForm.appendChild(playerEmailInput);
        gapDashForm.appendChild(this.playGameImagButton);
        gapDashForm.appendChild(backButton);
        this.add.dom(0, 0).createFromHTML(gapDashForm.outerHTML);
        document.body.appendChild(gapDashForm);
        backButton.addEventListener('click', () => {
            //preventDefault();
            this._audioManager.stopBackgroundMusic();
            this.scene.start(constants_1.SceneCons.LandingPageScene);
        });
    }
}
exports.MainMenuScene = MainMenuScene;
