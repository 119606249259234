"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BootScene = void 0;
const constants_1 = require("../constants");
class BootScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'BootScene',
        });
    }
    create() { }
    preload() {
        this.addBackgroundImage();
        this.load.on('complete', function () { }, this);
        // load out package
        this.load.pack('preload', './assets/pack.json', 'preload');
    }
    update() {
        this.scene.start(constants_1.SceneCons.LandingPageScene);
    }
    addBackgroundImage() {
        const divWrapper = document.createElement('div');
        divWrapper.id = 'loading-wrapper';
        divWrapper.className = 'common-sence-bg';
        const divLoading = document.createElement('div');
        divLoading.className = 'div-loading';
        divWrapper.appendChild(divLoading);
        const spanLoading = document.createElement('span');
        spanLoading.className = 'loading-text';
        spanLoading.innerHTML = 'Please wait...';
        divLoading.appendChild(spanLoading);
        this.add.dom(0, 0).createFromHTML(divWrapper.outerHTML);
        document.body.appendChild(divWrapper);
    }
}
exports.BootScene = BootScene;
